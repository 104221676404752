<template>
  <div>
    <Co2Desktop v-if="$vuetify.breakpoint.mdAndUp"></Co2Desktop>
    <Co2Mobile v-if="$vuetify.breakpoint.mdAndDown"></Co2Mobile>
  </div>
</template>

<script>
export default {
  name: "Co2",

  components: {
    Co2Mobile: () => import("./Co2Mobile.vue"),
    Co2Desktop: () => import("./Co2Desktop.vue"),
  },
};
</script>

<style></style>
